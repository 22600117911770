<script>
import Vertical from "./vertical";

/**
 * Main Layout
 */
export default {
    components: {
        Vertical
    },
    data() {
        return {};
    },
    mounted() {
        document.body.classList.remove('authentication-bg')
    },
    methods: {},
};
</script>
<template>
<div>
    <Vertical>
        <slot />
    </Vertical>
</div>
</template>