// {
//     id: 1,
//     label: "menuitems.dashboard.text",
//     icon: "uil-home-alt",
//     badge: {
//         variant: "primary",
//         text: "menuitems.dashboard.badge"
//     },
//     link: "/"
// },

export const menuItems = [
    {
        id: 1,
        label: "menuitems.dashboard.text",
        icon: "uil-home-alt",
        link: "/"
    },{
        id: 2,
        label: "menuitems.customer.text",
        icon: "uil-user-circle",
        link: "/customer"
    },{
        id: 3,
        label: "menuitems.fleetTracking.text",
        icon: "uil-map-marker-alt",
        link: "/fleet"
    },{
        id: 4,
        label: "menuitems.report.text",
        icon: "uil-invoice",
        link: "/report"
    },{
        id: 5,
        label: "menuitems.setting.text",
        icon: "uil-cog",
        link: "/setting"
    },{
        id: 6,
        label: "menuitems.users.text",
        icon: "uil-users-alt",
        link: "/users"
    }
];
