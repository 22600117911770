<script>
import simplebar from 'simplebar-vue';
import systemService from '../api/systemService';
export default {
  components: {
    simplebar,
  },
  data() {
    return {
      languages: [
        {
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'English',
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
      userData: null,
      notification_page: 1,
      notifications: [],
    };
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === this.$i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    this.initUserData()
    this.loadNotification()
  },
  methods: {
    showStatus(status) {
      const statuses = ['New', 'Loan & Document', 'Require Install', 'Final Inspection', 'Disbursement', 'Live'];
      return statuses[status];
    },
    loadNotification() {
      systemService.lastestUpdates(this.notification_page)
        .then((response) => {
          this.notifications = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    displayRole(role_id) {
      const roles = ['Admin', 'Agent', 'Verifier', 'Authorizer', 'Installer', 'Lender'];
      return roles[role_id - 1];
    },
    initUserData() {
      systemService.getProfile()
        .then((response) => {
          this.userData = response;
          localStorage.setItem('userData', JSON.stringify(response));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    logoutUser() {
      this.logout();
      this.$router.push({
        path: '/account/login',
      });
    },
  },
};
</script>

<template>
  <header id="page-topbar" style="background-color: #441620;">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="20" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="20" />
            </span>
          </router-link>
        </div>

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

      </div>

      <div class="d-flex">
        <b-dropdown
          variant="white"
          class="d-inline-block d-lg-none ms-2"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-search"></i>
          </template>
          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('navbar.search.text')"
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            <span style="color: white;">{{ text }}</span>
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :link-class="{ active: entry.language === current_language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          variant="white"
          class="dropdown d-inline-block"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-bell" style="color: white;"></i>
            <span class="badge bg-danger rounded-pill" style="color: white;"></span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h5>
              </div>
              <div class="col-auto">
                <a href="#!" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>

          <simplebar style="max-height: 230px" data-simplebar>
            <a :href="`/customer/id?id=${item.customer_id}`" class="text-reset notification-item" v-for="item, i in notifications" :key="i">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16" >
                    <i class="uil-file"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ item.customer.name }} &nbsp; <span class="badge bg-warning">{{ showStatus(item.customer.status) }}</span>
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      <span class="badge bg-success">{{ displayRole(item.staff.role_id) }}</span> &nbsp; {{ item.comment }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ item.created_at }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
        </b-dropdown>

        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item"
          right
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <span
              class="d-none d-xl-inline-block ms-1 fw-medium font-size-15"
              style="color: white;" >{{ userData?.name }}</span
            ><br>
            <span
              class="d-none d-xl-inline-block ms-1 fw-medium font-size-15"
              style="color: white;" >({{ displayRole(userData?.role_id) }})</span
            >
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </template>

          <!-- item-->
          <a class="dropdown-item" href="#">
            <i
              class="uil uil-user-circle font-size-18 align-middle text-muted me-1"
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.profile")
            }}</span>
          </a>
          <a class="dropdown-item" href="/logout">
            <i
              class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.logout")
            }}</span>
          </a>
        </b-dropdown>

        <div style="display: flex;
    justify-content: center;
    flex-direction: column;">
          <img src="@/assets/images/logo-sm.png" alt height="50" />
        </div>
      </div>
    </div>
  </header>
</template>
