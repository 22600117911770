<script>
export default {};
</script>

<template>
  <footer class="footer">
    <!-- <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">{{ new Date().getFullYear()}} © Infinity Platform.</div>
        </div>
    </div> -->
  </footer>
</template>
